import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import "../../styles/services.scss"
import News from "../../components/News"
import OdooTopSection from "../../components/OdooTopSection"
import WhyChooseVisiomateforD365 from "../../components/WhyChooseVisiomateforD365"
import SimplifiedStepstogetyouStarted from "../../components/SimplifiedStepstogetyouStarted"
import Wethoughtyoumighthavequestions from "../../components/Wethoughtyoumighthavequestions"
import IconsAdded from "../../components/IconsAdded"
const Dynamics365FinanceOperations = ({ data }) => {
  return (
    <div className="dynamics-parent-class">
      <div className="dynamics365FinanceOperations">
        <Layout pageTitle={"Visiomate - Dynamics 365 Finance Operation"}
        descrip = {"Streamline your financial and operational processes with Microsoft Dynamics 365 Finance & Operations. Gain real-time insights, improve decision-making, and drive business growth."}>
          <OdooTopSection
            headerData={{
              title:
                "At Visiomate, we specialize in seamlessly transforming businesses through expert D365 implementation and unwavering support services",
            }}
            data={data}
          />
          <div className="position-relative">
          <WhyChooseVisiomateforD365
            data={{
              title: "Why Choose Visiomate for D365?",
              headings: [
                {
                  heading: "Crafting Tailored Solutions",
                  description:
                    "At Visiomate, our seasoned professionals recognize the distinctiveness of every business. We pride ourselves on developing bespoke D365 solutions meticulously crafted to harmonize seamlessly with your organization's goals and processes.",
                },
                {
                  heading: "Seamless End-to-End Implementation",
                  description:
                    "From the drawing board to flawless execution, our comprehensive D365 implementation services pave the way for a seamless transition. Unlock the full potential of Microsoft's robust platform, empowering your business for success.",
                },
                {
                  heading: "Proactive Support at Every Turn",
                  description:
                    "Our commitment goes beyond implementation. We stand steadfast by your side with proactive support services. Our dedicated team ensures the continuous seamless operation of your D365 environment, offering timely assistance, updates, and a commitment to ongoing improvements.",
                },
                {
                  heading: "Navigating Industries with Expertise",
                  description:
                    "With a diverse portfolio spanning the apparel and footwear sector, Visiomate brings a wealth of industry-specific knowledge to the table. Whether you're dealing with fashion trends, inventory management, or supply chain intricacies, our expertise is tailored to elevate your operations.",
                },
              ],
            }}
            dataFormImages={data}
          />
          <IconsAdded iconColor={"yellow"}/>
          </div>
          {/* <RecentSuccessStories
            data={{
              title: "Recent Success Stories ",
              Text: [
                {
                  description:
                    "Our commitment goes beyond implementation. We stand steadfast by your side with proactive support services. ",
                  image: img1,
                },
                {
                  description:
                    "Our commitment goes beyond implementation. We stand steadfast by your side with proactive support services. ",
                  image: img2,
                },
              ],
            }}
            dataFormImages={data}
          /> */}
          <SimplifiedStepstogetyouStarted />
          <Wethoughtyoumighthavequestions
            data={[
              {
                question: "What is Dynamics 365 (D365)?",
                answer:
                  "Dynamics 365 is a suite of cloud-based business applications developed by Microsoft. It combines Customer Relationship Management (CRM) and Enterprise Resource Planning (ERP) capabilities into a unified platform, enabling organizations to manage various aspects of their business operations, including sales, marketing, customer service, finance, operations, and more.",
                answer1: "",
                answer2: "",
                answer3: "",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What are the key benefits of implementing Dynamics 365?",
                answer:
                  "- Improved efficiency and productivity through automation of business processes.",
                answer1:
                  "- Enhanced customer engagement and satisfaction with personalized experiences.",
                answer2:
                  "- Better decision-making with real-time insights and analytics.",
                answer3:
                  "- Scalability to accommodate business growth and changing requirements.",
                answer4:
                  "- Integration with other Microsoft products and services for seamless collaboration and data sharing.",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What are the different modules available in Dynamics 365?",
                answer:
                  "Dynamics 365 offers a wide range of modules and applications to address various business needs, including:",
                answer1:
                  "- Sales: Manage leads, opportunities, and sales pipelines.",
                answer2:
                  "- Customer Service: Provide omnichannel support and deliver exceptional customer experiences.",
                answer3:
                  "- Marketing: Plan, execute, and analyze marketing campaigns.",
                answer4:
                  "- Field Service: Schedule, dispatch, and track field service activities.",
                answer5:
                  "- Finance: Streamline financial processes, including accounting, budgeting, and forecasting.",
                answer6:
                  "- Supply Chain Management: Optimize inventory, procurement, and logistics operations.",
                answer7:
                  "- Human Resources: Manage employee data, payroll, benefits, and performance.",
                answer8:
                  "- Project Service Automation: Plan, execute, and monitor projects, including resource allocation and time tracking.",
                answer9: "",
              },
              {
                question: "How long does it take to implement Dynamics 365?",
                answer:
                  "The duration of a Dynamics 365 implementation can vary depending on factors such as the scope of the project, complexity of business processes, customization requirements, and organizational readiness. Typically, implementations can range from a few months to a year or more, with phased approaches to rollout and adoption.",
                answer1: "",
                answer2: "",
                answer3: "",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What is the implementation methodology used for Dynamics 365?",
                answer:
                  "Microsoft offers several implementation methodologies for Dynamics 365, including Sure Step, Agile, and RapidStart. These methodologies provide structured frameworks for planning, executing, and managing Dynamics 365 implementations, ensuring successful outcomes and alignment with organizational goals.",
                answer1: "",
                answer2: "",
                answer3: "",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "What are the key considerations for a successful Dynamics 365 implementation?",
                answer:
                  "Some key considerations for a successful Dynamics 365 implementation include:",
                answer1:
                  "- Clearly defining project goals, objectives, and success criteria.",
                answer2:
                  "- Engaging stakeholders and obtaining buy-in from key decision-makers.",
                answer3:
                  "- Conducting thorough business process analysis and requirements gathering.",
                answer4:
                  "- Selecting the right implementation partner with expertise in Dynamics 365.",
                answer5:
                  "- Planning for data migration, integration, and customization needs.",
                answer6:
                  "- Providing comprehensive training and change management support for end-users.",
                answer7: "",
                answer8: "",
                answer9: "",
              },
              {
                question:
                  "How can I get support and assistance during the Dynamics 365 implementation process?",
                answer:
                  "Microsoft provides various resources and support options for organizations undertaking Dynamics 365 implementations, including:",
                answer1:
                  "- Access to technical documentation, guides, and training materials.",
                answer2:
                  "- Online forums, communities, and user groups for knowledge sharing and collaboration.",
                answer3:
                  "- Support from Microsoft Certified Partners and Dynamics 365 experts for consulting, implementation, and ongoing support services.",
                answer4: "",
                answer5: "",
                answer6: "",
                answer7: "",
                answer8: "",
                answer9: "",
              },
            ]}
            title="We thought you might have questions"
          />
          <News isAbout={true} career={true} />
        </Layout>
      </div>
    </div>
  )
}

export default Dynamics365FinanceOperations

export const query = graphql`
  query Dynamics365FinanceOperationsImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odooimg: file(relativePath: { eq: "Maskgroupcopy.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages1: file(
      relativePath: { eq: "DemoImages/jaredd-craig-HH4WBGNyltc-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    RSSImages2: file(
      relativePath: {
        eq: "DemoImages/kourosh-qaffari-RrhhzitYizg-unsplash.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
