import React, { memo } from "react"
import { useState } from "react"
import { Typography } from "antd"
import { Link } from "gatsby"
import Img from "gatsby-image"
import InfoModal from "../components/InfoModal"

const { Title } = Typography

const OdooTopSection = ({ headerData, data }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const bgImage = data.Odooimg.childImageSharp.fluid.src
  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  let isServiceOdoPage
  if (typeof window !== "undefined") {
    isServiceOdoPage = window.location.pathname.includes("/Odoo")
  }
  return (
    <div
      className="top-section"
      style={{ color: "red", backgroundImage: `url(${bgImage})` }}
    >
      <div className="logo">
        <Link to="/">
          {isServiceOdoPage ? (
            <Img fluid={data.VMLogoBlack.childImageSharp.fluid} alt="logo" />
          ) : (
            <Img fluid={data.VMLogoWhite.childImageSharp.fluid} alt="logo" />
          )}
        </Link>
      </div>
      <div className="headings-container">
        <Title>{headerData.title}</Title>
      </div>
      <button onClick={showModal}>Get a free consultancy</button>
      <InfoModal
        companyIsEnabled
        url="http://46.101.195.121:8066/email/ContactUs"
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  )
}

export default memo(OdooTopSection)
